<!--Módulo de Gestión de Avisos -->

<!-- Avisos Expendientes Detalle

funescom -> expediente -> avisos

fnt:        num, get, set
tip:         *get      1=avisos incidencias,2=recep.documentos
std:        *get      0,1 Pendientes, 2=Vistos
id:           *set       Id del aviso
txt:         *set       Texto respuesta observaciones
env:       *set       Envio Respuesta 0=no,1=email,2=sms
ema       *set       Email para envio
sms        *set       Telefono para sms -->

<template>
  <div class="avisosExpedientesM" v-if="loaded">  
    <div class="cab">DATOS</div> 
    <v-sheet :elevation="4">
      <div class="contenedor">

        <div>          
          <baseBtraExtra
            style="width:200px"
            :permExtra="permExtra"
            :modulo="btExtCfg"           
            @onEvent="execAccion">                          
          </baseBtraExtra>
        </div>
        
        <!---->
        <div class="columna" style="padding-top:10px">
          <div class="conflex">
            <uiText              
              v-model="ct.control[2]"
              :label="ct.control[1]"
              readonly>              
            </uiText>

            <uiText              
              v-model="ct.fallename[2]"
              :label="ct.fallename[1]"
              readonly>              
            </uiText>
          </div>   

          <v-textarea
            v-bind="$textarea"
            v-model="ct.obs[2]"
            :label="ct.obs[1]"
            rows="6"
            no-resize
            readonly>              
          </v-textarea>

          <v-textarea
            v-bind="$textarea"
            v-model="ct.txt[2]"
            :label="ct.txt[1]"
            background-color="green lighten-5"
            rows="6"            
            no-resize>                  
          </v-textarea>

          <uiText              
            v-model="ct.email[2]"
            :label="ct.email[1]"
            class="green lighten-5"
          ></uiText>

          <uiText            
            v-model="ct.movil[2]"
            :label="ct.movil[1]"
            class="green lighten-5"
          ></uiText>  

        </div>
      </div> 
    </v-sheet>


    <!-- Ventana de componente Expedientes (TEMPORAL) -->
    <v-dialog
      v-model="modalExp"
      content-class="modal"
      persistent>
        <div class="centrado">
          <component                 
            :is="componente_dinamico"
            :padre="storeName"
            :accion=0
            :accionRow="{ id:masterSyncRecord.exp_id }"
            :disparo="disparo"                
            @onEvent="$event.accion=='cerrar' || $event.accion==6? cerrar_dinamico() : ''">
          </component>
        </div>
    </v-dialog>

    <component
      syncUpdate
      :is="componenteDinamico"              
      :storeRaiz="storeName"
      :masterStore="storeName"
      :masterRecordField="fieldAux"
      :filtro="filtroAux"
      tipoFiltro="exp"
      @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
    </component>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js"; 
  import baseBtraExtra from "@/base/baseBtraExtra";
  import exp_M from "@/components/exp_M";
  import frasExpF from "@/components/frasExpF";
    
  export default {
    mixins: [mixM],
    components: { baseBtraExtra, exp_M, frasExpF },
    props: {},

    data() {
      return {
        stIni: {
          api: "avisosExpedientesM",
          name:"avisosExpedientesM",
          titulo:"",  
          recordAccess:"masterStore",
          masterStore:this.masterStore,
          mView:'',
          pView:[]
        }, 

        disparo:false,
        modalExp:false,
        componente_dinamico:null,

        // nueva version
        fieldAux:'',
        filtroAux:''
      };
    },


    methods: {      
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)"); 
        
        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { accion: "expedientes", icono: "mdi-alpha-e-circle-outline", title:'Ver/Editar Expediente' },
          { accion:'facturacion', icono: "mdi-alpha-f-circle-outline", title:'Facturación' },
          { accion:'envio_visto', icono: "mdi-check", title:'Visto/OK' },
          { accion:'envio_email', btn: "sendEmail" },
          { accion:'envio_sms', icono: "mdi-phone", title:'Enviar SMS' }
        ];              
    
      },


      //
      iniDataApi() {
        this.apiArgs.sch= null;
        this.apiArgs.get= ["", 'get', { tip:0, std:'0' }, "avisos"];
        this.apiArgs.visto= ["", 'visto', { tip:1, env:0, id:0, txt:'', ema:'', mov:'' }, "avisos"];
      },


      // configuro botonera
      crudFin() {
        this.botonerasSet();
      },

  
      // configuro estado botones
      botonerasSet() {        
        this.btnSet(this.btExtCfg, "expedientes", { disabled:!this.masterSyncRecord.id });
        this.btnSet(this.btExtCfg, "facturacion", { disabled:!this.masterSyncRecord.id });
        this.btnSet(this.btExtCfg, "envio_visto", { disabled:!this.masterSyncRecord.id || this.masterSyncRecord.visto!="" });
        this.btnSet(this.btExtCfg, "envio_email", { disabled:!this.masterSyncRecord.id });
        this.btnSet(this.btExtCfg, "envio_sms",   { disabled:!this.masterSyncRecord.id });
      },


      // no muestra mensaje de registro inválido
      noID() {},


      // muestro ventana expedientes
      async expedientes() {       
        /* if (!this.masterSyncRecord.id) {
          this.$root.$alert.open('Seleccione un Aviso', 'info');
          return;
        } */

        // cierra la ventana de expedientes si ya se encuentra abierta        
        if (this.$router.currentRoute.name=='exp_M') {
          await this.$router.push({ path:'/' });
        }
        
        this.disparo= !this.disparo;
        this.componente_dinamico= "exp_M";
        this.modalExp= true;        
        
        // nueva version
        /* this.fieldAux="exp_id";
        this.componenteDinamico= "expM"; */        
      },


      // muestro ventana facturación
      async facturacion() {
        this.filtroAux= this.masterSyncRecord.control;
        this.componenteDinamico= "frasExpF";
      },

      async envio_visto() {
        await this.visto();
      },

      async envio_email() {
        await this.visto(1);
      },

      async envio_sms() {
        await this.visto(2);
      },


      // env 0=visto, 1=email, 2=sms

      visto(env=0) { 
  
        if (env>=1 && !this.ct.txt[2])   return this.$root.$alert.open('Debe introducir Texto de Respuesta', 'info');
        if (env==1 && !this.ct.email[2]) return this.$root.$alert.open('Debe introducir Correo Electrónico', 'info');
        if (env==2 && !this.ct.movil[2]) return this.$root.$alert.open('Debe introducir Teléfono Movil', 'info');        
      
        let msg='<b><u>VISTO / OK Incidencias</u></b><br> Aceptamos / VISTO la indidencia ?<br>' + 
          (env==1 ? '<br>Se envia Correo Electronico con la respuesta a <b>' + this.ct.email[2] + '</b><br>' :'') +
          (env==2 ? '<br>Se envia SMS con la respuesta al Teléfono <b>' + this.ct.movil[2] + '</b><br>' :'');

        this.$root.$alert.open(msg , 'confirm', null, ["ACEPTAR", "CANCELAR"])
        .then(r=> { if (r== 0) this.vistoContinue(env) }
        );
      },

      //
      async vistoContinue(env) {

        let param = { apiArg: this.apiArgs.visto, args: { id:this.masterSyncRecord.id, env, txt: this.ct.txt[2], ema: this.ct.email[2], mov: this.ct.movil[2] }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // limpio schema
        this.record2ctrl({}, this.ct, true);

        // actualizo records
        this.masterStoreUpdate('refresh');
      },

      // 
      cerrar_dinamico() {
        this.modalExp= false;
        this.componente_dinamico= null;
      }

    },


    computed: {
      sch() {
        return {
          control: [ 'control', 'Control', '' ],
          fallename: [ 'fallename', 'Nombre', '' ],
          apd_cod: [ 'apd_cod', 'Apd Cod.', '' ],
          apd: [ 'apd', 'APD', '' ],
          estado: [ 'estado', 'Estado', '' ],          
          obs: [ 'obs', 'Estado', '' ],
          txt: [ 'visto_obs', 'Respuesta / Observaciones', '' ],
          email: [ 'email', 'Email', '' ],
          movil: [ 'movil', 'Móvil', '' ],            
          visto_fh: [ 'visto_fh', 'Fecha', '' ]
        }
      },


      incVisto() { 
        console.log("incVisto:", this.masterSyncRecord);       
        return this.masterSyncRecord.visto> '';
      },
    },


    watch: {
      masterSyncRecord() {
        if (!this.masterSyncRecord.id) this.record2ctrl({}, this.ct, true);        
        this.botonerasSet();
      }
    }
  };
</script>
